body {
  background-color: white !important;
}

.logo {
  width: 200px !important;
  margin-bottom: 10vh !important;
}

.subtitleWhite {
  color: white;
}

.aboutSection {
  border-top: 1px solid white;
  padding: 10vh 0 0 0;
}

.aboutSectionFooter {
  padding: 30vh 0 5vh 0;
}

.homePage {
  height: 90vh;
  padding-top: 20vh;
  background: url('../../../../img/bg_quest_foto.jpg');
  background-position: -200px 0;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 450px) {
    .homePage {
      height: 120vh;
      padding-top: 10vh;
      background: none;
    }
  }
