.link {
    text-decoration: none;
}

.buttonLink {
    padding: 4px 10px;
    font-size: 0.8125rem;
    background-color: rgb(0, 113, 185);
    color: white;
    text-decoration: none;
}


@media (max-width: 1050px) {
    .recommendations {
        display: none;
    }
}