.card {
  flex: 1;
  max-width: 300px;
  justify-content: space-around;
  box-shadow: 1px 0 20px 6px #70707030 !important;
  border-radius: 25px !important;
  font-family: 'Poppins';
}

@media (max-width: 450px) {
  .card {
    margin: 0 auto 20px auto;
  }
}

@media (min-width: 450px) {
  .card {
    margin-right: 20px;
  }
}

.card img {
  height: 5rem;
  padding-top: 25px;
}

.cardDescription {
  padding-bottom: 2rem;
}
