.dropdownType {
  max-height: 47px;
  background-color: white;
}

@media (max-width: 450px) {
  .dropdownType {
    margin-bottom: 15px !important;
  }

  .dropdownType .MuiInputBase-input {
    border: 1px solid #afafaf;
  }
}


@media (min-width: 450px) {
  .dropdownType .MuiInputBase-input {
    border: 10px solid #afafaf;
  }
}